import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_PunishmentEntry = _resolveComponent("PunishmentEntry")!
  const _component_PunishmentEntriesList = _resolveComponent("PunishmentEntriesList")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navbar, { "dismissible-login-modal": true }),
    _createVNode(_component_Container, null, {
      default: _withCtx(() => [
        _createVNode(_component_PunishmentEntriesList, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.punishments, (p) => {
              return (_openBlock(), _createBlock(_component_PunishmentEntry, {
                key: p.id,
                id: p.id,
                type: p.type,
                target: p.name,
                reason: p.reason,
                duration: p.end - p.start,
                server: p.server,
                unpunished: p.unpunished || !p.active || (p.end > 0 && p.end < Date.now()),
                style: _normalizeStyle({ cursor: 'pointer', 'background-color': $setup.highlight.includes(p.id) ? 'rgba(255, 255, 0, 0.2)' : null }),
                onClick: ($event: any) => ($options.redirectTo(p.id))
              }, null, 8, ["id", "type", "target", "reason", "duration", "server", "unpunished", "style", "onClick"]))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          text: "さらに取得",
          onClick: $options.fetchMore,
          disabled: $setup.disableFetchMoreButton || !$setup.hasNext
        }, null, 8, ["onClick", "disabled"])
      ]),
      _: 1
    })
  ], 64))
}